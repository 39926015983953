import awards from "./awards.js";
import configs from "./configs.js";
import cookies from "./cookies.js";
import featureManagement from "./featureManagement.js";
import hashes from "./hashes.js";
import icons from "./icons.js";
import images from "./images.js";
import meta from "./meta.js";
import modals from "./modals.js";
import resourceGroups from "./resourceGroups.js";
import routes from "./routes.js";
import storage from "./storage.js";
import templates from "./templates.js";

export default {
  //imports
  Configs: configs,
  Cookies: cookies,
  FeatureManagement: featureManagement,
  Hashes: hashes,
  Icons: icons,
  Images: images,
  Meta: meta,
  Modals: modals,
  ResourceGroups: resourceGroups,
  Routes: routes,
  SessionStorage: storage.SessionStorage,
  LocalStorage: storage.LocalStorage,
  Templates: templates,
  //common
  Env: {
    CorpUrl: process.env.VUE_APP_CORP_API_URI
      ? process.env.VUE_APP_CORP_API_URI
      : window.location.origin + "/corp",
    BrandtierUrl: process.env.VUE_APP_BRANDTIER_URL,
    AssetsUrl: window.location.hostname.includes("test")
      ? "https://test-assets.pildyk.lt"
      : "https://assets.pildyk.lt",
  },
  Resources: {},
  Configurations: {},
  AppVersionCheckIntervalInSeconds: 45,
  NarsykPlansDisplaySection: {
    Services: 4,
    Voice: 3,
    Loan: 5,
    Internet: 2,
  },
  PricePlanOrderStatus: {
    DefaultSuccess: 0,
    Campaign: 1,
  },
  PaymentMethods: {
    Account: 0,
    CreditCard: 1,
    SavedCreditCard: 2,
    All: 3,
    Bank: 4,
    GooglePay: 5,
    ApplePay: 6,
  },
  CreditCardStatusMap: {
    0: "Aktyvuota",
    1: "Neaktyvuota",
    2: "Laukiama",
    3: "Pašalinta",
    4: "Atjungta",
    5: "Keičiama",
  },
  CreditCardStatus:{
    Active: 0,
    Deactivated: 1,
  },
  HeaderImageAligns: {
    Top: "top",
    Bottom: "bottom",
    Middle: "middle",
  },
  Events: {
    MainResourcesLoaded: "mainResourcesLoaded",
    ConfirmPaymentCard: "confirmPaymentCard",
    UpdateInformationBanners: "updateInformationBanners",
  },
  PricePlanPurchasedResult: {
    CampaignMessage: "CampaignMessage",
    UseCreditCardOnce: "UseCreditCardOnce",
    SaveCreditCardToProfile: "SaveCreditCardToProfile",
    SetCreditCardAsDefaultPaymentType: "SetCreditCardAsDefaultPaymentType",
    Failure: "Failure",
    GenericError: "GenericError",
    PeopleMagazineOffer: "PeopleMagazineOffer",
  },
  PolarPopupType: {
    Success: "success",
    Error: "error",
  },
  ModalControl: {
    Open: "open-modal",
    Close: "close-modal",
  },
  DateFormat: " YYYY MMMM D",
  MsisdnPrefix: "+370",
  GaScriptPayments: {
    Topup: "Top up",
    CardPayment: "Card payment",
    AccountPayment: "Account payment",
  },
  CampaignMessageType: {
    Info: 0,
    Offer: 1,
    VP: 2,
    Lead: 4,
  },
  SocialLogins: {
    Google: "google",
    Facebook: "facebook",
  },
  ConsentStatus: {
    Agree: 1,
    Disagree: 2,
  },
  ReturnParameter: "returnUrl",
  Settings: {
    HiddenPageKey: "73ee18ae-8c1f-4eeb-9b02-40207434f7e6",
  },
  CustomSlides: {
    Christmas2021: {
      Component: "t2-christmas-2021-slide",
      Id: "Christmas2021",
    },
  },
  HelpSlugs: {
    ESim: "esim",
  },
  LanguagesMap: {
    LT: "lt",
    EN: "en",
    RU: "ru",
  },
  DefaultLanguage: "lt",
  Languages: ["lt", "en", "ru", "ua"],
  AwardsCampaign: awards,
  ConsentCampaignComponents: {
    Main: "t2-consents-campaign-main",
    Consents: "t2-consents-campaign-consents",
    Saved: "t2-consents-campaign-saved",
    Activated: "t2-consents-campaign-activated",
  },
  ConsentCampaignRulesLink: "https://pildyk.lt/akcijos/xbox-taisykles",
  CoverageMapIframeUrl: "https://emap.telcoq.com/lt/frame/4bea0661-dfcb-11eb-aad5-7446a0a8fa29",
  NarsykAuthenticationResults: {
    UserNotFound: 0,
    Success: 1,
    UnknownError: 2,
    ChallengeRequired: 3,
    AutoLoginDisabled: 4,
    SmsChallengeThrottle: 5,
  },
  CustomTariffs: {
    Standard: "Standard",
  },
  TokenExpirationWindow: 5,
  TokenExchangeTimeout: 5000,
  ActiveCampaigns: null,
  AvailableCampaigns: {
    Wheel: "wheel",
    ItemPurchase: "AirPods",
    Refill: "prize",
    Sim: "sim",
  },
  CampaignStatus: {
    Inactive: 0,
    Active: 1,
    ActiveTransactionOnly: 2,
  },
  UkrainianLanguage: "ua",
  VasProviderId: {
    Whalebone: 1,
  },
  Whalebone: {
    Tabs: {
      Incidents: "t2-incidents",
      Settings: "t2-settings",
    },
    IncidentIcons: ["malicious", "malware", "phishing"],
    FilterTypes: {
      Security: 0,
      Adult: 1,
      Entertainment: 2,
      Crime: 3,
      Advertisement: 4,
    },
    ModalEvents: {
      OpenWhitelistDomains: "open-whalebone-whitelist-domains",
      OpenBlacklistDomains: "open-whalebone-blacklist-domains",
      OpenFilterExceptions: "open-whalebone-filter-exceptions",
    },
  },
  ItemPurchaseCampaignPrizesTypes: {
    Sk: "SK",
    Fsg: "FSG",
    Jauti: "JAUTI",
  },
  CampaignTransactionStatuses: {
    Invalid: 0,
    Valid: 1,
    ValidPromo: 2,
  },
  LiveChatConfigurations: {
    Registration: {
      license: "13897302",
      group: "15",
      customerName: "Pildyk naudotojas",
      chatBetweenGroups: false,
    },
    Dashboard: {
      license: "13897302",
      email: null,
      group: "2",
      visibility: "minimized",
      chatBetweenGroups: false,
    },
  },
  GoogleMapApiKey: "AIzaSyBx3ip7H3pmnLvbmp55oCujCU84RYANVNA",
  EShop: {
    IgnoredPercentage: 2,
    HiddenPercentage: 10,
  },
  ScreenBreakpoints: {
    Sm: 768,
  },
  ProgressBarStates: {
    Finished: 1,
    Active: 0,
    Inactive: -1,
  },
  WscActionSourceId: 1
};
